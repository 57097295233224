.main-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.game-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lobby {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.game {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.playTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid green;
  background-color: greenyellow;
  max-width: 70vw; 
  min-width: 70vw; 
  min-height: 80vh; 
}

.playerRotation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.currentTurnPlayer {
  margin: 10px;
  border: 3px solid red;
}

.player {
  margin: 10px;
  padding-top: 3px;
}

.cardsAlign {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.game-over {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}